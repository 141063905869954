import React from "react";
import Star from "../../../../static/images/star.png";
const RatingReview = (props: any) => {
    const { title, subtitle } = props;
    return (
        <>
            <div className="container mx-auto px-4">
                <section className="internet-section4">                
                    <h2 className="h2 text-secondary text-center">{title}</h2>
                    <p className="p2 text-black text-center mb-12">{subtitle}</p>
                    <div className="max-width1000 grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        <div className="internet-section4__box">
                            <h4 className="h4">Anonymous, Halifax, Nova Scotia</h4>
                            <p className="p2">I lost my job unexpectedly, and it was hard keeping up with my bills. The Neighbor in Need program at Purple Cow helped me stay connected while I got back on my feet. It’s amazing to see a company that genuinely cares about their customers.</p>
                        </div>
                        <div className="internet-section4__box">
                            <h4 className="h4">Anonymous, Sackville, Nova Scotia</h4>
                            <p className="p2">When an unexpected medical expense hit, I wasn’t sure how I’d pay my internet bill. Purple Cow’s Neighbor in Need program stepped in and covered it for me. The process was so simple, and it helped me focus on getting through a tough time without losing my internet.</p>
                        </div>
                        <div className="internet-section4__box">
                            <h4 className="h4">Anonymous, Charlottetown, PEI</h4>
                            <p className="p2">I’ve always loved Purple Cow, but their Neighbor in Need program took it to another level. When finances got tight, they made sure I stayed connected. Knowing that they have my back made all the difference.</p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default RatingReview
